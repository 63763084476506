import React, { useEffect, useState, useRef, useContext } from 'react';
import { useStaticQuery, graphql, Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { Formik, FieldArray } from 'formik';
import axios from 'axios';
import loadjs from 'loadjs';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { createMuiTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import { FaCheckSquare } from "react-icons/fa";
import * as styles from './contactUsForm.module.css';
import SectionHeader from '../SectionHeader/sectionHeader';
import { useUpdateOpacityOnScrollIntoView } from '../helperHooks';
import { WishlistContext } from '../../provider/WishlistProvider';
import { getPrice } from '../../utils/shopifyUtils';

export const CssTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: '#994e2e',
    },
    '& label.Mui-error': {
      color: 'red'
    },
    '& label': {
      color: '#994e2e',
      fontFamily: "'Crimson', serif",
      fontSize: '20px'
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#994e2e',
    },
    '& .MuiInput-underline:before': {
      borderBottomColor: '#994e2e',
    },
    '& .MuiInput-underline:hover': {
      borderBottomColor: '#994e2e',
    },
    '& .Mui-error.MuiInput-underline:after': {
      borderBottomColor: 'red',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#994e2e',
      },
      '&:hover fieldset': {
        borderColor: '#994e2e',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#994e2e',
      },
    }
    
  },
})(TextField);

const useDateStyles = makeStyles({
  root: {
    position: 'relative',
    marginBottom: '12px',
    width: '100%',
    marginTop: '20px',
    fontFamily: '"Crimson", serif',
    '& label.Mui-focused': {
      color: '#994e2e',
    },
    '& label.Mui-error': {
      color: 'red'
    },
    '& label': {
      color: '#994e2e',
      fontFamily: "'Crimson', serif",
      fontSize: '20px'
    },
    '& .MuiInputBase-root': {
      borderBottomColor: '#994e2e',
    },
    '& .MuiInput-underline': {
      borderBottomColor: '#994e2e',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#994e2e',
    },
    '& .MuiInput-underline:before': {
      borderBottomColor: '#994e2e',
    },
    '& .MuiInput-underline:hover': {
      borderBottomColor: '#994e2e',
    },
    '& .Mui-error.MuiInput-underline:after': {
      borderBottomColor: 'red',
    },
  }
});

const dateTheme = createMuiTheme({
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: "#DA6B46",
      },
    },
    MuiPickersCalendarHeader: {
      switchHeader: {
        // backgroundColor: lightBlue.A200,
        // color: "white",
      },
    },
    MuiPickersDay: {
      day: {
        color: "#DA6B46",
      },
      daySelected: {
        backgroundColor: "#DA6B46",
      },
      // dayDisabled: {
      //   color: lightBlue["100"],
      // },
      current: {
        color: "#DA6B46",
      },
    },
    MuiPickersModal: {
      dialogAction: {
        color: "#DA6B46",
      },
    },
  },
});

const subjects = [
  { value: 'rentals', label: 'RENTALS FOR MY EVENT' },
  { value: 'eventStyling', label: 'EVENT STYLING' },
  { value: 'customCommision', label: 'CUSTOM COMMISIONED PIECE' },
  { value: 'wholesale', label: 'WHOLESALE INQUIRY' },
  { value: 'order', label: 'MY ORDER' },
  { value: 'other', label: 'OTHER' }
]

const ContactUsForm = ({ style, includeWishList }) => {
  const [isSuccess, setIsSuccess] = useState(false);
  const [recaptchaLoaded, setRecapthcaLoaded] = useState(false);
  const textRef = useRef(null);
  const { wishlist } = useContext(WishlistContext);
  const dateStyles = useDateStyles();

  useUpdateOpacityOnScrollIntoView(textRef, 125);
  

  useEffect(() => {
    loadjs(`https://www.google.com/recaptcha/api.js?render=${process.env.GATSBY_RECAPTCHA_CLIENT_ID}`, {
      returnPromise: true,
      numRetries: 3
    })
      .then(() => { setRecapthcaLoaded(true); })
      .catch(() => { setRecapthcaLoaded(false); })
  }, []);

  let styleOveride = {};

  if (style) {
    styleOveride = style;
  }

  return (
    <div className={styles.picAndFormContainer} style={styleOveride}>
      <div className={styles.container}>
        <div style={{ width: '100%' }}> {
          isSuccess
            ?
            <div className={styles.successContainer}>
              <p className={styles.text}>
                Your message was successfully sent!
                <br />
                Thanks for reaching out. Someone will be in contact with you shortly.
              </p>
              <div className={styles.checkMark}>
                {/* <FaCheckSquare /> */}
                <svg className={styles.checkmark} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                  <circle className={styles.checkmarkCircle} cx="26" cy="26" r="25" fill="none" />
                  <path className={styles.checkmarkCheck} fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                </svg>
              </div>
            </div>
            :
            (<Formik
              initialValues={{ name: '', email: '', subject: includeWishList ? ["rentals"] : [], text: '', rentalItems: wishlist.lineItems.map(item => item.title), rentalDate: null }}
              validate={values => {
                const errors = {};
                if (!values.email) {
                  errors.email = 'Required';
                } else if (
                  !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                ) {
                  errors.email = 'Invalid email address';
                }

                return errors;
              }}
              onSubmit={(values, { setSubmitting, setErrors }) => {
                window.grecaptcha.ready(function () {
                  window.grecaptcha.execute(process.env.GATSBY_RECAPTCHA_CLIENT_ID, { action: 'submit' }).then(function (token) {
                    axios.post('/.netlify/functions/sendMail', { values, token }).then(function (response) {
                      setSubmitting(false);
                      setIsSuccess(true);
                    }).catch(function (error) {
                      setSubmitting(false);
                      setErrors({
                        global: 'Error requesting the service'
                      });
                    });
                  });
                });
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                setFieldValue
                /* and other goodies */
              }) => (
                <form onSubmit={handleSubmit} className={styles.form}>
                  <CssTextField
                    type="text"
                    name="name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    label="Name"
                    className={styles.input}
                    value={values.name}
                  />
                  <CssTextField
                    error={errors.email && touched.email}
                    type="text"
                    name="email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    label="Email"
                    helperText={errors.email && touched.email ? 'Please enter a valid email.' : undefined}
                    className={styles.input}
                    value={values.email}
                  />
                  <div className={styles.wishlistContainer}>
                    {includeWishList && (
                      <>
                      <div className={styles.subjectTitle}>My Wishlist:</div>
                      { wishlist.lineItems.map(wish => (
                        <div className={styles.wishlistItem}>
                          <GatsbyImage image={wish.images[0].gatsbyImageData} className={styles.wishPic} />
                          <div className={styles.wishlistTitle}>{wish.title}</div>
                          <div className={styles.wishlistTitle}>{`${getPrice(wish.variants[0].price)} ${wish.totalInventory > 1 ? "per item" : ""}`}</div>
                        </div>
                      ))}
                      {wishlist.lineItems.length === 0 && <div className={styles.wishlistItem}><Link to="/rentals/items" className={styles.wishlistEmptyLink}>Wishlist is empty. Visit the rentals page to view our list of curated items.</Link></div>}
                      </>
                    )}
                  </div>
                  <div className={styles.datePickerContainer}>
                    {includeWishList && (
                      <ThemeProvider theme={dateTheme}>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardDatePicker
                            label="Rental Date:"
                            placeholder="07/07/2021"
                            value={values.rentalDate}
                            onChange={(date) => {
                              setFieldValue('rentalDate', date);
                            }}
                            format="MM/dd/yyyy"
                            emptyLabel=""
                            disablePast
                            className={dateStyles.root}
                          />
                      </MuiPickersUtilsProvider>
                      </ThemeProvider>
                    )}
                  </div>
                  <FieldArray
                    name="subject"
                    render={arrayHelpers => (
                      <div className={styles.subjectContainer}>
                        <div className={styles.subjectTitle}>I’m inquiring about...</div>
                        {subjects.map(subject => (
                          <label className={styles.subjectItem}>
                            <input
                              name="subjects"
                              type="checkbox"
                              value={subject}
                              checked={values.subject.includes(subject.value)}
                              onChange={e => {
                                if (e.target.checked) {
                                  arrayHelpers.push(subject.value);
                                } else {
                                  const idx = values.subject.indexOf(subject.value);
                                  arrayHelpers.remove(idx);
                                }
                              }}
                              className={styles.subjectCheckbox}
                            />
                            <span className={styles.subjectCheckboxLabel}>{subject.label}</span>
                          </label>
                        ))}
                      </div>
                    )}
                  />
                  <div className={styles.messageContainer}>
                    <CssTextField
                      type="text"
                      name="text"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      label="Message"
                      value={values.text}
                      className={styles.textArea}
                      multiline
                      variant="outlined"
                      rows={7}
                    />
                  </div>
                  <div className={styles.submitContainer}>
                    <Button type="submit" disabled={isSubmitting || !recaptchaLoaded} className={styles.submitButton}>
                      Submit
                    </Button>
                  </div>
                  {errors.global ? errors.global : null}
                </form>
              )}
            </Formik>)
        }
        </div>
      </div>
    </div>
  );
};

export default ContactUsForm;